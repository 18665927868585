import React                    from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage }     from 'react-intl';
import { find }                 from 'lodash';

import { findT }                from '@interness/web-core/src/components/_helpers';
import Link                     from '@interness/web-core/src/components/elements/Link/Link';
import PhoneNumber              from '@interness/web-core/src/components/text/PhoneNumber/PhoneNumber';



const ContactWidget = props => {
  const translations = findT(props.widget.translations, props.lang);
  return <StaticQuery query={query} render={data => {
    const { emails, numbers } = data.directusCompany;
    const email = find(emails, email => email.primary).email;
    const phoneNumber = find(numbers, number => number.type === 'phone' && number.primary);
    const faxNumber = find(numbers, number => number.type === 'fax' && number.primary);
    return (
      <>
        <h4>{translations.header
          ? translations.header
          : <FormattedMessage id='components.widgets.contactWidget.defaultHeader'/>}
        </h4>
        <ul>
          {phoneNumber &&
          <li><FormattedMessage id='components.widgets.addressWidget.tel'/> <PhoneNumber number={phoneNumber.number}/>
          </li>}
          {faxNumber &&
          <li><FormattedMessage id='components.widgets.addressWidget.fax'/> <PhoneNumber number={faxNumber.number}
                                                                                         title={'Fax'}
                                                                                         callable={false}/></li>}
          {email && <li><FormattedMessage id='components.widgets.addressWidget.email'/> <a href={`mailto:${email}`}
                                                                                           title='Email'>{email}</a>
          </li>}
          <li><Link style={{color: '#FF4E02'}} to={'/terminvereinbarung'}>Online Terminvereinbarung</Link></li>
        </ul>
      </>
    )
  }}/>
};

const query = graphql`
  query {
    directusCompany {
      emails {
        email
        primary
      }
      numbers {
        number
        primary
        type
      }
    }
  }
`;

export default ContactWidget;
